.refer-bar {
  .MuiFilledInput-input {
    padding: 4px 12px 10px !important;
  }

  .date-time-picker {
    .MuiIconButton-label {
      color: var(--primaryText) !important;
    }
  }

  .MuiInput-underline {
    border-bottom: 0.5px solid gray !important;
  }

  .Mui-focused {
    color: #f44336 !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #3F98D2 !important;
  }
}

.MuiPickersDay-daySelected {
  color: #fff !important;
  font-weight: 500;
  background-color: #3f51b5;
}

.MuiIconButton-root {
  outline: none !important;
}

.icon-button-pt-name {
  color: '#fff' !important;
  outline: none !important;
}

.card-refer {
  color: var(--primaryText) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  background-color: white !important;

  .card-header {
    color: var(--primaryText) !important;
  }

  .MuiTypography-colorTextSecondary {
    color: var(--secondaryText) !important;
  }

  .MuiIconButton-label {
    color: var(--primaryText) !important;
  }

  .card-content {
    padding-top: 4px !important;
  }

  .avatar-icon {
    background-color: var(--primaryTheme) !important;
    font-size: 14px;
  }
}

.refer-type-icon-0 {
  background-color: #66d4f0 !important;
  font-size: 14px;
  color: #fff !important;
}

.refer-type-icon-1 {
  background-color: #FEECEB !important;
  font-size: 14px;
  color: #F44336 !important;
  border: 1px solid #F44336;
  border-radius: 4px;
  align-self: center
}

.refer-type-icon-2 {
  background-color: #FFE2F1 !important;
  font-size: 14px;
  color: #D64C83 !important;
  border: 1px solid #FFB9DE;
  border-radius: 4px;
  align-self: center
}

.refer-type-icon-3 {
  background-color: #FFF5E5 !important;
  font-size: 14px;
  color: #FFB74C !important;
  border: 1px solid #FFB74C;
  border-radius: 4px;
  align-self: center
}

.refer-type-icon-4 {
  background-color: #EDF7ED !important;
  font-size: 14px;
  color: #4CAF50 !important;
  border: 1px solid #4CAF50;
  border-radius: 4px;
  align-self: center
}

.refer-type-icon-5 {
  background-color: #F9FAFA !important;
  font-size: 14px;
  color: #7E8388 !important;
  border: 1px solid #A5ABB2;
  border-radius: 4px;
  align-self: center
}

.refer-type-icon-6 {
  background-color: #A5ABB2 !important;
  font-size: 14px;
  color: white !important;
}

.refer-chart-box {
  padding-top: 20px;
  padding-left: 10px;
  background: #fefefe;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.1);

  .apexcharts-menu {
    color: #000 !important;
  }

  .apexcharts-tooltip {
    background: #f3f3f3;
    color: black;
  }
}

.refer-action-menu {
  .MuiMenu-list {
    background-color: var(--secondaryBg) !important;
    color: var(--primaryText) !important;
  }

  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.refer-dialog-accept {
  .MuiDialog-paper {
    background-color: #84ffa0 !important;
  }

  .MuiDialogContent-root {
    background-color: #eee !important;
    color: #000 !important;
  }

  .MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.9);
  }

  .MuiDialogActions-root {
    background-color: #eee !important;
    color: #000 !important;
  }
}

.refer-dialog-reject {
  .MuiDialog-paper {
    background-color: #d1392e !important;
    color: #fff !important;
  }

  .MuiDialogContent-root {
    background-color: #eee !important;
    color: #000 !important;
  }

  .MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.9);
  }

  .MuiDialogActions-root {
    background-color: #eee !important;
    color: #000 !important;
  }
}

.inline {
  display: 'inline';
}

.refer-in-icon-color {
  color: green;
}

.refer-out-icon-color {
  color: red;
}
