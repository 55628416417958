.MuiDrawer-paperAnchorLeft {
  background-color: var(--MuiDrawerPaperAnchorLeft) !important;
}

.MuiDrawer-paper {
  overflow-y: unset !important;
}

.MuiSelect-icon {
  color: var(--primaryText) !important;
}

.MuiSelect-select {
  color: var(--primaryText) !important;
}

.MuiTableCell-stickyHeader {
  background-color: var(--tableHeader) !important;
  color: var(--primaryText) !important;
}

.MuiDrawer-paperAnchorLeft {
  background-color: var(--AppBarBgColorCustom) !important;
}

.MuiBackdrop-root {
  background-color: var(--MuiBackdropBgColor) !important;
}

.MuiDialogContentText-Login {
  margin-bottom: 2px !important;
}

.MuiDialog-paper {
  background-color: var(--primaryBg) !important;
}

.MuiDialogContent-root {
  background-color: var(--primaryBg) !important;
  color: var(--primaryText) !important;
}

.MuiDialogActions-root {
  background-color: var(--primaryBg) !important;
  color: var(--primaryText) !important;
}

.MuiInputBase-input {
  color: var(--primaryText);
}

.MuiInputLabel-marginDense {
  color: var(--secondaryText) !important;
}

.MuiInputLabel-shrink {
  color: var(--secondaryText) !important;
}

.Mui-focused {
  color: var(--MuiFormLabelColor) !important;
}
