html {
  /* global colors */
  --primaryTheme: #e52863;
  --primaryBg: #fff;
  --secondaryBg: #eee;
  --appNameTextBgColor: rgba(60, 60, 60, 1);
  --primaryText: #212121;
  --secondaryText: #7b7b7b;
  --primaryButtonText: #fff;
  --primaryBorderBg: #efefef;
  --activeSelectionBg: rgba(162, 162, 162, 0.1);
  --primaryShadowBg: rgba(204, 204, 204, 0.7);
  --MuiFormLabelColor: #3f51b5;
  --MuiDrawerPaperAnchorLeft: #ffffff;
  --ListItemActiveBg: rgba(0, 0, 0, 0.1);
  --ListItemActiveText: #e83d84;
  --AppBarBgColorCustom: rgba(255, 255, 255, 0.99);
  --AppBarTextColorCustom: #212121;
  --tableHeader: #7b7b7b;
  --MuiBackdropBgColor: rgba(245, 245, 245, 0.95);
  --MuiListItemButtonHover: rgba(200, 200, 200, 0.3);
  --NotifyBgColorCustom: rgba(255, 255, 255, 0.99);
}
