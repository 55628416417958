html[theme='dark'] {
  /* global colors */
  --primaryTheme: #e52863;
  --primaryBg: #1a1a1d;
  --secondaryBg: #2c2c2c;
  --appNameTextBgColor: rgba(10, 10, 10, 1);
  --primaryText: rgba(255, 255, 255, 0.8);
  --secondaryText: rgba(255, 255, 255, 0.6);
  --primaryButtonText: #fff;
  --primaryBorderBg: #292929;
  --activeSelectionBg: rgba(0, 0, 0, 0.18);
  --primaryShadowBg: rgba(8, 8, 8, 0.7);
  --MuiFormLabelColor: #3f51b5;
  --MuiDrawerPaperAnchorLeft: #dddddd;
  --ListItemActiveBg: rgba(0, 0, 0, 0.8);
  --ListItemActiveText: rgba(255, 255, 255, 0.8);
  --AppBarBgColorCustom: rgba(26, 26, 29, 0.99);
  --AppBarTextColorCustom: #fff;
  --tableHeader: #7b7b7b;
  --MuiBackdropBgColor: rgba(0, 0, 0, 0.95);
  --MuiListItemButtonHover: rgba(255, 255, 255, 0.1);
  --NotifyBgColorCustom: rgba(36, 37, 38, 1);
}
